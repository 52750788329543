var render = function () {
  var _vm$state$activeAssig2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', _vm._g(_vm._b({
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.assignments,
      "search": _vm.state.tableSearchTerm,
      "loading": _vm.state.isLoadingAssignments,
      "with-notes-and-tasks": "",
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalAssignments,
      "with-duplicate": ""
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onClickEdit,
      "click:duplicate": function clickDuplicate($event) {
        return _vm.listeners.onClickDuplicateAssignment($event);
      },
      "click:info": function clickInfo($event) {
        return _vm.listeners.onClickInfo($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onClickDelete($event);
      },
      "click:tasks": function clickTasks($event) {
        return _vm.listeners.onOpenTasksDialog($event);
      },
      "click:notes": function clickNotes($event) {
        return _vm.listeners.onOpenNotesDialog($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_vm._t("table-header", function () {
          return [_c('v-row', {
            staticClass: "pb-3 ma-0",
            attrs: {
              "align": "center"
            }
          }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
            staticClass: "mr-5 rounded-lg white",
            attrs: {
              "text": "",
              "elevation": "0",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.listeners.onToggleFilterDropdown();
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "left": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
            attrs: {
              "right": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e(), _c('CommonExportMenuExtended', {
            attrs: {
              "loading": _vm.state.isLoadingExport
            },
            on: {
              "export": function _export($event) {
                return _vm.listeners.onExportAssignments($event);
              }
            }
          }), _vm.functions.hasSufficientRights(_vm.constants.Rights.ASSIGNMENT_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.CONTRACT_READ_ALL) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
            staticClass: "ml-auto rounded-lg",
            attrs: {
              "color": "primary",
              "elevation": "0"
            },
            on: {
              "click": function click($event) {
                _vm.state.isAddEditAssignmentDialogOpen = true;
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "left": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('contractData.assignments.actions.addAssignment')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
            attrs: {
              "mode": "in-out"
            }
          }, [_vm.state.isFilterDropdownOpen ? _c('AssignmentsFilterBar', {
            staticClass: "d-flex align-center",
            attrs: {
              "fields": _vm.constants.FILTER_FIELDS,
              "without-person-dropdown": _vm.withoutPersonDropdown
            }
          }) : _vm._e()], 1)];
        })];
      },
      proxy: true
    }, {
      key: "positionId",
      fn: function fn(_ref) {
        var _item$position, _item$position2;

        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex justify-space-between align-center"
        }, [_c('span', [_vm._v(" " + _vm._s((_item$position = item.position) === null || _item$position === void 0 ? void 0 : _item$position.id) + " ")]), (_item$position2 = item.position) !== null && _item$position2 !== void 0 && _item$position2.id && (_vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_READ_OWN)) ? _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.listeners.onOpenPosition(item.position.id);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiArrowRight) + " ")])], 1) : _vm._e()], 1)];
      }
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.ASSIGNMENT_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "contracts",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(item.contracts, function (contract) {
          return _c('span', {
            key: contract.id
          }, [_vm._v(" " + _vm._s(contract.name) + " ")]);
        });
      }
    }, {
      key: "header.organizationalUnit.name",
      fn: function fn(_ref3) {
        var header = _ref3.header;
        return [_c('div', {
          staticClass: "relative"
        }, [_vm._t("orgUnit"), _c('span', [_vm._v(_vm._s(header.text))])], 2)];
      }
    }, {
      key: "header.professionalUnit.name",
      fn: function fn(_ref4) {
        var header = _ref4.header;
        return [_c('div', {
          staticClass: "relative"
        }, [_vm._t("profUnit"), _c('span', [_vm._v(_vm._s(header.text))])], 2)];
      }
    }, {
      key: "header.scope",
      fn: function fn(_ref5) {
        var header = _ref5.header;
        return [_c('div', {
          staticClass: "relative"
        }, [_vm._t("scope"), _c('span', [_vm._v(_vm._s(header.text))])], 2)];
      }
    }], null, true)
  }, 'CommonTableView', _vm.$attrs, false), _vm.$listeners), [_vm.state.isRowInfoDialogOpen ? _c('CommonInfoDialog', {
    attrs: {
      "entity": _vm.state.activeAssignment,
      "properties-to-show": _vm.constants.PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      "translation-key": "assignments"
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isRowInfoDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isRowInfoDialogOpen", $$v);
      },
      expression: "state.isRowInfoDialogOpen"
    }
  }) : _vm._e(), _vm.state.isTasksDialogOpen ? _c('CommonTasksDialog', {
    attrs: {
      "tasks": _vm.state.assignmentTasks,
      "entity-name": "assignment",
      "entity-id": _vm.state.tasksEntityId
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.listeners.onReloadTasks();
      },
      "close": function close($event) {
        _vm.state.isTasksDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isTasksDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTasksDialogOpen", $$v);
      },
      expression: "state.isTasksDialogOpen"
    }
  }) : _vm._e(), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.assignmentNotes,
      "entity-name": "assignment",
      "entity-id": _vm.state.notesEntityId
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": function close($event) {
        _vm.state.isNotesDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditAssignmentDialogOpen ? _c('AddEditAssignmentDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode,
      "assignment-to-edit": _vm.state.activeAssignment,
      "assignment-to-duplicate": _vm.state.activeAssignmentToDuplicate
    },
    on: {
      "reload-assignments": function reloadAssignments($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      },
      "added-edited": function addedEdited($event) {
        return _vm.listeners.onAddedEditedAssignments();
      }
    },
    model: {
      value: _vm.state.isAddEditAssignmentDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditAssignmentDialogOpen", $$v);
      },
      expression: "state.isAddEditAssignmentDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteAssignmentDialogOpen ? _c('CommonDeleteDialog', {
    attrs: {
      "is-loading": _vm.state.isLoadingDeleteAssignment
    },
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteAssignment();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "warning",
      fn: function fn() {
        var _vm$state$activeAssig;

        return [(_vm$state$activeAssig = _vm.state.activeAssignment) !== null && _vm$state$activeAssig !== void 0 && _vm$state$activeAssig.hasHoursRecorded ? _c('v-alert', {
          attrs: {
            "dense": "",
            "type": "warning",
            "outlined": "",
            "color": "warning",
            "icon": _vm.icons.mdiAlert,
            "prominent": ""
          }
        }, [_c('v-row', [_c('v-col', {
          staticClass: "text-center"
        }, [_c('span', {
          staticClass: "text-subtitle-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('contractData.assignments.dialog.warning.text')) + " ")])])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 446064049),
    model: {
      value: _vm.state.isDeleteAssignmentDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteAssignmentDialogOpen", $$v);
      },
      expression: "state.isDeleteAssignmentDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('contractData.assignments.dialog.delete.text', {
    id: (_vm$state$activeAssig2 = _vm.state.activeAssignment) === null || _vm$state$activeAssig2 === void 0 ? void 0 : _vm$state$activeAssig2.id
  })) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }