enum FILTER_FIELD_KEY {
  AssignmentIds = 'ids',
  PersonIds = 'personIds',
  ProfessionalUnitIds = 'professionalUnitIds',
  ProfessionalUnitIdsWithChildren = 'professionalUnitIdsWithChildren',
  AssignmentProfileIds = 'profileIds',
  ContractualRelationship = 'contractualRelationship',
  StartFrom = 'startFrom',
  StartTo = 'startTo',
  EndFrom = 'endFrom',
  EndTo = 'endTo',
  Active = 'active',
}

export { FILTER_FIELD_KEY }
