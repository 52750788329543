
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiFileExport } from '@mdi/js'

import CommonMonthDateInput from '@/components/common/CommonMonthDateInput.vue'

export default defineComponent({
  name: 'CommonExportMenuExtended',
  components: {
    CommonMonthDateInput,
  },
  inheritAttrs: false,
  setup: (_, { emit }) => {
    const isMenuOpen = ref(false)

    const formElement = ref<HTMLFormElement | null>(null)

    const isFormValid = ref(false)

    const startMonth = ref<string | null>(null)
    const endMonth = ref<string | null>(null)

    const isTimeSpanValid = computed(() => {
      if (isFormValid.value && startMonth.value && endMonth.value) {
        const reversedStartMonth = startMonth.value.split('.').reverse().join('.').replace('.', '')
        const reversedEndMonth = endMonth.value.split('.').reverse().join('.').replace('.', '')

        const difference = Number(reversedEndMonth) - Number(reversedStartMonth)

        if (difference <= 1000 && difference >= 0) {
          return true
        }
      }

      return false
    })

    function onExport(): void {
      emit('export', {
        startMonth: startMonth.value,
        endMonth: endMonth.value,
        isExtendedExport: isExtendedExport.value,
      })

      close()
    }

    const isExtendedExport = ref<boolean>(false)

    function close() {
      isMenuOpen.value = false
      isExtendedExport.value = false
    }

    const hasCancelButtonFocus = ref(false)

    return reactive({
      formElement,
      icons: {
        mdiFileExport,
      },
      state: {
        isMenuOpen,

        isFormValid,

        startMonth,
        endMonth,

        isTimeSpanValid,
        isExtendedExport,

        hasCancelButtonFocus,
      },
      listeners: {
        onExport,
      },
      functions: {
        close,
      },
    })
  },
})
