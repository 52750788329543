
import { defineComponent, reactive, ref, computed, PropType, watch } from '@vue/composition-api'
import { mdiMagnify, mdiPlus, mdiChevronDown, mdiChevronUp, mdiFilter, mdiAlert, mdiArrowRight } from '@mdi/js'
import { cloneDeep, isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenuExtended from '@/components/common/CommonExportMenuExtended.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import {
  useDeleteAssignment,
  useExportAssignments,
  useExportAssignmentsExtended,
  useGetAssignments,
  useGetAssignmentsBasic,
} from '@/api/assignment'
import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetPersonsBasic } from '@/api/person'
import { useGetProfilesBasic } from '@/api/profile'

import { dateDotNotationToDash } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { FIELD_TYPE, FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/contractData/views/assignments/types'
import { AssignmentOutput } from '@/api/types/assignment'
import { Rights } from '@/api/types/right'
import { CONTRACTUAL_RELATIONSHIP } from '@/views/types'
import { useNotify } from '@/store'
import { PositionId } from '@/api/types/position'
import { PROFILE_TYPE } from '@/api/types/profile'

interface AdditionalFilters {
  personIds: number
  startFrom: string
  endFrom: string
}

export default defineComponent({
  name: 'Assignments',
  components: {
    CommonTableView,
    CommonExportMenuExtended,
    AddEditAssignmentDialog: () =>
      import('@/views/contractData/views/assignments/components/AddEditAssignmentDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    AssignmentsFilterBar: () => import('@/views/contractData/views/assignments/components/AssignmentsFilterBar.vue'),
  },
  props: {
    componentTableHeaders: {
      type: Array,
      default: () => [],
    },
    additionalFilters: {
      type: Object as PropType<AdditionalFilters>,
      default: () => ({}),
    },
    withoutFilterPersistence: {
      type: Boolean,
      default: false,
    },
    withoutPersonDropdown: {
      type: Boolean,
      default: false,
    },
    rowPointer: {
      type: Boolean,
      default: false,
    },
    assignmentsNeedReload: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const VIEW_TABLE_HEADERS = [
      {
        text: root.$t('contractData.assignments.table.col.title.id'),
        value: 'id',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.position.id'),
        value: 'positionId',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.person'),
        value: 'person.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.professionalUnit'),
        value: 'professionalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.profile'),
        value: 'profile.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.contract'),
        value: 'contracts',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.tenderingProfileNumber'),
        value: 'tenderingProfileNumber',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.assignments.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const TABLE_HEADERS = props.componentTableHeaders.length ? props.componentTableHeaders : VIEW_TABLE_HEADERS

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof AssignmentOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'demand',
      'start',
      'end',
      'scope',
      'tendering',
    ]

    const tableSearchTerm = ref('')

    const isAddEditAssignmentDialogOpen = ref(false)

    const isEditMode = ref(false)

    const activeAssignment = ref<AssignmentOutput | null>(null)
    const activeAssignmentToDuplicate = ref<AssignmentOutput | null>(null)

    function onClickEdit(_, { item: assignment }: { item: AssignmentOutput }): void {
      if (
        hasSufficientRights(Rights.ASSIGNMENT_UPDATE) &&
        hasSufficientRights(Rights.CONTRACT_READ_ALL) &&
        hasSufficientRights(Rights.BASIC_READ)
      ) {
        isEditMode.value = true

        isAddEditAssignmentDialogOpen.value = true

        activeAssignment.value = { ...assignment }
      }
    }

    const isDeleteAssignmentDialogOpen = ref(false)

    function onClickDelete(assignment: AssignmentOutput): void {
      activeAssignment.value = { ...assignment }

      isDeleteAssignmentDialogOpen.value = true
    }

    const { deleteAssignment, isLoading: isLoadingDeleteAssignment } = useDeleteAssignment()

    async function onDeleteAssignment(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteAssignment(activeAssignment.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteAssignmentDialogOpen.value = false

      activeAssignment.value = null

      debouncedCb.value()

      init()

      emit('reload-profile-data')
    }

    function onClickDuplicateAssignment(assignment: AssignmentOutput): void {
      isAddEditAssignmentDialogOpen.value = true
      let updatedForm = deleteId({ ...assignment })

      activeAssignmentToDuplicate.value = updatedForm
    }

    function deleteId<T extends object>(form: T): T {
      const updatedForm = cloneDeep(form)

      Object.entries(updatedForm).forEach(() => {
        delete updatedForm['id']
      })

      return updatedForm
    }

    const isRowInfoDialogOpen = ref(false)

    function onClickInfo(assignment: AssignmentOutput): void {
      activeAssignment.value = { ...assignment }

      isRowInfoDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddEditAssignmentDialogOpen.value = false

      isDeleteAssignmentDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isEditMode.value = false

      activeAssignment.value = null
      activeAssignmentToDuplicate.value = null
    }

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnitsBasic } = useGetProfUnitsBasic()

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfilesBasic } = useGetProfilesBasic()

    const { exec: getPersonsBasic, data: persons, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()
    const {
      exec: getAssignmentsBasic,
      data: assignmentsBasic,
      isLoading: isLoadingAssignmentsBasic,
    } = useGetAssignmentsBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !profUnits.value?.length &&
          !profiles.value?.length &&
          !persons.value?.length &&
          !assignmentsBasic.value?.length
        ) {
          getProfUnitsBasic()
          getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })
          getPersonsBasic()
          getAssignmentsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.AssignmentIds,
        value: null,
        label: root.$t('contractData.assignments.filters.assignmentIds'),
        items: computed(() => assignmentsBasic.value ?? []),
        isLoading: computed(() => isLoadingAssignmentsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.PersonIds,
        value: null,
        label: root.$t('contractData.assignments.filters.persons'),
        items: computed(() => persons.value ?? []),
        isLoading: computed(() => isLoadingPersonsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIds,
        value: null,
        label: root.$t('contractData.assignments.filters.profUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren,
        value: null,
        label: root.$t('contractData.assignments.filters.profUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.AssignmentProfileIds,
        value: null,
        label: root.$t('contractData.assignments.filters.profiles'),
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfilesBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ContractualRelationship,
        value: null,
        label: root.$t('contractData.assignments.filters.contractualRelationships'),
        items: computed(() => CONTRACTUAL_RELATIONSHIP),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StartFrom,
        value: null,
        label: root.$t('contractData.assignments.filters.startFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.EndFrom,
        value: null,
        label: root.$t('contractData.assignments.filters.endFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.StartTo,
        value: null,
        label: root.$t('contractData.assignments.filters.startTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.EndTo,
        value: null,
        label: root.$t('contractData.assignments.filters.endTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.Active,
        value: null,
        label: root.$t('contractData.assignments.filters.active'),
        items: [],
        dataTyp: FilterDataTypes.BooleanDate,
      },
    ])

    const {
      exec: getAssignments,
      data: assignments,
      isLoading: isLoadingAssignments,
      paginationResponse,
    } = useGetAssignments()

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init,
      props.withoutFilterPersistence
    )
    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    // Default sort by start if table is being rendered within Profile (and has start column)
    if (props.componentTableHeaders.length) {
      setSort(vuetifyTableOptions, { by: 'start', desc: true })
    }

    const totalAssignments = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      if (Object.keys(props.additionalFilters).length) {
        await getAssignments({
          params: {
            ...paginationParams.value,
            ...filterFieldsObject,
            ...props.additionalFilters,
          },
        })
      } else {
        await getAssignments({ params: { ...paginationParams.value, ...filterFieldsObject } })
      }
    }

    watch(
      () => props.assignmentsNeedReload,
      () => {
        if (props.assignmentsNeedReload) {
          debouncedCb.value()
          emit('reloaded-assignments')
        }
      },
      { immediate: true }
    )

    const { exportAssignments, isLoading: isLoadingExportAssignments } = useExportAssignments()
    const { exportAssignments: exportAssignmentsExtended, isLoading: isLoadingExportAssignmentsExtended } =
      useExportAssignmentsExtended()

    async function onExportAssignments(exportDetails: {
      startMonth: string
      endMonth: string
      isExtendedExport: boolean
    }) {
      try {
        if (exportDetails.isExtendedExport) {
          await exportAssignmentsExtended({
            ...filterFieldsObject.value,
            ...props.additionalFilters,
            startMonth: dateDotNotationToDash(exportDetails.startMonth),
            endMonth: dateDotNotationToDash(exportDetails.endMonth),
          })
        } else {
          await exportAssignments({
            ...filterFieldsObject.value,
            ...props.additionalFilters,
            startMonth: dateDotNotationToDash(exportDetails.startMonth),
            endMonth: dateDotNotationToDash(exportDetails.endMonth),
          })
        }

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const isLoadingExport = computed(
      () => isLoadingExportAssignments.value || isLoadingExportAssignmentsExtended.value
    )

    function onAddedEditedAssignments() {
      debouncedCb.value()
      emit('reload-person')
      emit('reload-profile-data')
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: assignmentNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<AssignmentOutput>(assignments, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: assignmentTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<AssignmentOutput>(assignments, () => init(filterFieldsObject.value))

    function onOpenPosition(positionId: PositionId): void {
      root.$router.push({
        name: 'position-details',
        params: { positionId: String(positionId) },
      })
    }

    return reactive({
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiFilter,
        mdiChevronDown,
        mdiChevronUp,
        mdiAlert,
        mdiArrowRight,
      },
      constants: {
        Rights,

        FIELD_TYPE,
        FILTER_FIELDS,
        FILTER_FIELD_KEY,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      },
      state: {
        assignments,
        isLoadingAssignments,
        totalAssignments,
        vuetifyTableOptions,

        profiles,

        profUnits,

        tableSearchTerm,

        isAddEditAssignmentDialogOpen,
        isEditMode,

        activeAssignment,
        activeAssignmentToDuplicate,

        isDeleteAssignmentDialogOpen,
        isLoadingDeleteAssignment,

        isRowInfoDialogOpen,

        isNotesDialogOpen,
        assignmentNotes,
        notesEntityId,

        isTasksDialogOpen,
        assignmentTasks,
        tasksEntityId,

        isFilterDropdownOpen,

        isLoadingExport,
      },
      listeners: {
        onClickEdit,
        onClickDuplicateAssignment,

        onClickDelete,
        onDeleteAssignment,

        onExportAssignments,

        onClickInfo,

        onCloseAddEditDeleteInfoDialog,

        onOpenNotesDialog,
        onReloadNotes,

        onOpenTasksDialog,
        onReloadTasks,

        onToggleFilterDropdown,

        onAddedEditedAssignments,

        onOpenPosition,
      },
      functions: {
        debouncedCb,

        hasSufficientRights,
      },
    })
  },
})
